.search_tabs_container {
  width: 100%;
}
.search_tabs_container ul {
  margin-bottom: 15px !important;
  border-bottom: none !important;
  padding: 0 10px !important;
}
.search_tabs_container ul li {
  margin-right: 10px;
}

@media screen and (max-width: 576px) {
  .search_tabs_container ul li {
    margin-right: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 10px !important;
  }
}

.search_tabs_container ul li a {
  border: none !important;
  border: 1px solid #fd5d63 !important;
  color: #fd5d63 !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  transition: all 0.4s;
}

.tab_content {
  border-color: transparent !important;
  padding: 6px 0 !important;
}

.website_search_btn a,
.website_search_btn button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #df1b23;
  color: #ffffff;
  height: 100%;
  border: none;
  padding: 0.5rem;
  transition: all 0.4s;
}

.website_search_btn button:disabled {
  background-color: #f38185;
}

.website_search_btn a:hover {
  background-color: #000000;
}

.website_search_wrapper {
  position: relative;
}

.website_search_results_container {
  position: absolute;
  top: 93%;
  left: 0;
  width: 100%;
  height: auto;
  max-height: 280px;
  overflow-y: auto;
  background-color: #ffffff;
  box-shadow: 0 0 7px #e7e7e7;
  z-index: 1000;
  transition: all 0.4s;
}

.website_result_group h6 {
  padding: 15px 20px;
  color: #b1b1b1;
  margin-bottom: 0;
  font-size: 14px !important;
  font-weight: 600;
}

.website_result_group ul {
  padding: 0 !important;
  margin: 0 !important;
}

.website_result_group ul li {
  padding: 8px 20px !important;
  cursor: pointer !important;
  margin-bottom: 0 !important;
  color: #00233a;
  font-weight: 600;
  margin-right: 0 !important;
  transition: all 0.4s;
}

.website_result_group ul li a {
  display: inline-block;
  width: 100%;
  color: #000000 !important;
  font-weight: 600 !important;
  padding: 0 !important;
  border: none !important;
}

.website_result_group ul li:hover {
  background-color: #eeeeee;
}

.loading_wrapper {
  text-align: center;
  padding: 1rem;
  max-height: 100px;
}

.loading_wrapper img {
  width: 30px;
  height: auto;
}

.search_tours_btn:disabled,
.search_vacations_btn:disabled {
  background-color: #f38185 !important;
  cursor: not-allowed !important;
}

.slider_main_btn {
  position: relative;
  min-width: 200px !important;
  border-radius: 50px;
  z-index: 1;
  padding: 1.2rem 1rem;
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: capitalize;
  border: 1px solid #fab7b7;
  top: 0;
  transition: all 0.4s;
}

.slider_main_btn::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  background-color: #fda9ab;
  z-index: -1;
  animation: bubble 1s linear infinite;
  transition: all 0.4s;
}

.slider_main_btn:hover {
  top: -6px;
  background-color: #f84f55;
  backface-visibility: hidden;
}

@keyframes bubble {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.35);
    transform: scale(1.35);
  }
}
